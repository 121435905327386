import React from 'react';
import { graphql } from 'gatsby';

import * as Core from 'components/core';
import Layout from 'components/departments/DepartmentLayout';

import Heading from 'components/real-world/Heading';
import { QuoteImageProps } from 'components/core/Quote';

interface Props {
  data: {
    quoteImageKhashayar: QuoteImageProps;
    studentInternshipImage: Core.ImageAttributes;
    studentPracticumImage: Core.ImageAttributes;
    studentPracticumImage2: Core.ImageAttributes;
  };
}

const DepartmentContent: React.FC<Props> = (props) => {
  const {
    quoteImageKhashayar,
    studentInternshipImage,
    studentPracticumImage,
    studentPracticumImage2
  } = props.data;

  const headingSections: {
    title: string;
    slug: string;
    description: string;
  }[] = [
    {
      title: 'Student Internship',
      slug: 'student-internship',
      description:
        '“Even if you’re going to the local school down the street, you have to pay around $200 a year. It’s a lot for these kids and their families.”'
    },
    {
      title: 'Student Practicum',
      slug: 'student-practicum',
      description:
        'Vietnam’s poorer regions remain “left behind” in key developmental indicators, including hygienic sanitation and access to healthy water.'
    },
    {
      title: 'Student Project',
      slug: 'student-project',
      description:
        '“It’s not my fundraiser. It’s the fundraiser that I started, but it belongs to everybody who donated.”'
    }
  ];

  return (
    <Layout departmentSlug="real-world" title="Real World">
      <Heading
        title="Navigating roadblocks to education for young students in Kenya...Assessing the market for clean drinking water in Vietnam...Raising funds in the aftermath of Pittsburgh’s Tree of Life synagogue shooting."
        headingSections={headingSections}
      />

      <Core.ContentWrapper>
        <Core.ContentSection id="student-internship">
          <Core.Flag>Student Internship</Core.Flag>
          <Core.PrimaryHeading>
            Reaching Out with Compassion in Kibera
          </Core.PrimaryHeading>
          <p>
            <Core.LeadIn>In Kenya,</Core.LeadIn> high school isn’t cheap.
            Especially for young people growing up in Kibera—a suburb of more
            than half a million people southwest of Nairobi that is the largest
            low-income area in Africa.
          </p>
          <p>
            “Even if you’re going to the local school down the street, you have
            to pay around $200 a year,” says{' '}
            <Core.BoldText>Swathi Ayyagari</Core.BoldText>, a second-year
            master’s student. “It’s a lot for these kids and their families.”
          </p>
          <p>
            Ayyagari recently completed a three-month internship at Reaching Out
            with Compassion in Kibera, a nongovernmental organization that helps
            students navigate these challenges. ROCK offers scholarships,
            mentoring, meals, and a library space to help students reach for
            academic success.
          </p>
        </Core.ContentSection>
        <Core.ContentSection>
          <Core.FullWidthImage
            image={studentInternshipImage}
            alt="reaching out with compassion in kiberia"
            caption="In Kibera, students receive academic tutoring and participate in
            life skills workshops through ROCK."
          />
          <p>
            Ayyagari, who is part of the Energy, Resources and Environment
            Program and has an interest in both east Africa and international
            development, says ROCK’s local focus was an excellent fit. “It’s
            rare to find experiences where you are working directly with the
            beneficiaries,” she observes, “rather than just sitting in an
            office.”
          </p>
          <p>
            SAIS connections pointed Ayyagari to the internship.{' '}
            <Core.BoldText>Ghoncheh Lee</Core.BoldText>, MIPP ’18, is one of
            ROCK’s executive officers and recruited her classmate. “We needed a
            fresh set of eyes to help us think outside the box and introduce new
            methods that helped take our organization to the next level,” says
            Lee.
          </p>
          <p>
            Lee adds that Ayyagari “was able to wear several different hats” in
            her time at ROCK, including work in fundraising and social media
            training. She also tutored students in academics and gave life
            skills workshops, collaborated with a community reproductive
            outreach effort, and even wrote the organization’s annual report.
          </p>
          <Core.Quote
            author="Ghoncheh Lee"
            authorTitle="MIPP ’18, ROCK executive officer"
          >
            We needed a fresh set of eyes to help us think outside the box and
            introduce new methods that helped take our organization to the next
            level.
          </Core.Quote>
          <p>
            Ayyagari did it all in a challenging environment “that gave me an
            understanding of the problems [in Kenya] and also the potential for
            development,” she says. “It’s been helpful to have that
            on-the-ground experience and see what’s actually happening with a
            project.”
          </p>
          <Core.SignOff>Richard Byrne</Core.SignOff>
          <Core.Share id="student-internship" />
        </Core.ContentSection>

        <Core.HorizontalRule />

        <Core.ContentSection id="student-practicum">
          <Core.Flag>Student Practicum</Core.Flag>
          <Core.PrimaryHeading>
            Assessing the Market for Clean Water in Vietnam
          </Core.PrimaryHeading>
        </Core.ContentSection>
        <Core.ContentSection
          marginalia={
            <>
              <Core.FullWidthImage
                image={studentPracticumImage}
                alt="clean water illustration"
                contentSectionPlacement="marginalia"
              />
              <Core.Blockquote color="blue" author="Ayushi Trivedi">
                Even the poorest people were aware of the health benefits of
                filtered water and preferred to save for a higher-priced reverse
                osmosis filter, rather than invest in a ceramic product for the
                short term.
              </Core.Blockquote>
            </>
          }
        >
          <p>
            <Core.LeadIn>Clean drinking water</Core.LeadIn> is essential. But at
            what price? Second-year international development major{' '}
            <Core.BoldText>Ayushi Trivedi</Core.BoldText> was part of a
            four-member practicum team that traveled to Vietnam last January
            during winter break to assess the market for water filtration
            products in lower-income areas.
          </p>

          <p>
            While Vietnam has seen surging economic growth, there is a downside:
            less funding from international donors. Trivedi says Vietnam’s
            poorer regions remain “left behind” in key developmental indicators,
            including hygienic sanitation and access to healthy water.
          </p>
          <p>
            Trivedi’s team worked with iDE, an international nongovernmental
            organization, to gauge interest in low-income households for a
            ceramic filter made by a Cambodian company named Hydrologic. The
            team worked mainly in the north Vietnamese province of Tuyen Quang.
          </p>

          <p>
            “The private sector in Vietnam is thriving,” says Trivedi, “and has
            great opportunities to enter the development space” to tackle water
            filtration challenges. Yet her team also found that extensive
            governmental regulation and intervention, uneven competition, and a
            proliferation of cheap knockoff products are also shaping the market
            landscape.
          </p>
          <Core.FullWidthImage
            image={studentPracticumImage2}
            alt="Ayushi Trivedi, Tanvi Nagpal and Rachel Rose"
            caption="Ayushi Trivedi, center, with faculty member Tanvi Nagpal, left and alumna Rachel Rose, right"
          />
          <p>
            Trivedi says consumers at all income levels were increasingly savvy
            about issues connected to the necessity and costs of obtaining clean
            water. “Even the poorest people were aware of the health benefits of
            filtered water and preferred to save for a higher-priced reverse
            osmosis filter, rather than invest in a ceramic product for the
            short term,” she says.
          </p>
          <p>
            The trip was the capstone of a two-semester practicum course for
            international development students designed to use classroom skills
            in research and analysis to work with outside clients.{' '}
            <Core.BoldText>Rachel Rose</Core.BoldText> ’15, a research and
            evaluation specialist at iDE who sponsored the practicum, was
            impressed by their work. “Every time I connected with the practicum
            group,” she says, “they would be prepared, organized, and
            professional.”
          </p>
          <p>
            The practicum was a key reason Trivedi chose to attend SAIS. “It
            allowed us to be professionally engaged with a client for a year,
            contribute to their organizational goals, and work closely with
            their teams to inform their decisions through our research,” she
            says.
          </p>
          <Core.SignOff>Richard Byrne</Core.SignOff>
          <Core.Share id="student-practicum" />
        </Core.ContentSection>
        <Core.HorizontalRule />

        <Core.ContentSection id="student-project">
          <Core.Flag>Student Project</Core.Flag>
          <Core.PrimaryHeading>
            Raising Funds in the Aftermath of Tragedy
          </Core.PrimaryHeading>
          <p>
            <Core.LeadIn>Kashayar &ldquo;Shay&rdquo; Khatiri</Core.LeadIn> ’20,
            first learned of the shooting at the Tree of Life synagogue in
            Pittsburgh, in which a gunman killed 11 people, after his friend
            woke him up to tell him about the devastating news. Khatiri wanted
            to do something to benefit the victims and survivors, and aimed for
            an impact beyond the small amount he himself could donate.
            Recognizing the generosity of Americans, he quickly launched a
            GoFundMe campaign to support the synagogue, with a goal of $50,000
            in donations.
          </p>
          <p>
            Within hours, the campaign was on track to far exceed its
            target—surpassing $500,000 the first day with more than 48,000
            contributors—prompting Khatiri to increase the goal to $1 million
            (which was exceeded by Nov. 9).
          </p>
          <p>
            The money raised will go directly to the congregation, and he hopes
            that the funds will be used to help the families and the
            congregation recover. “It’s not my fundraiser. It’s the fundraiser
            that I started, but it belongs to everybody who donated,” he told{' '}
            <em>The Times of Israel.</em>
          </p>
          <p>
            While pursuing his master’s in strategic studies at SAIS, Khatiri
            has learned a great deal about interstate conflicts through his
            courses. Examining those conflicts, particularly World War II, has
            given him greater insight into how distrust in public and civil
            society institutions and their weakening directly relate to the rise
            of ideological extremism and global conflicts.
          </p>
          <Core.Quote
            image={quoteImageKhashayar}
            author="Kashayar &ldquo;Shay&rdquo; Khatiri &rsquo;20"
          >
            It’s not my fundraiser. It’s the fundraiser that I started, but it
            belongs to everybody who donated.
          </Core.Quote>
          <p>
            Khatiri says it has been eye-opening to see that extremists often
            rise when the public loses faith in establishment politics—whether
            for sound reasons or not. He believes that to minimize the spread of
            ideological violence and extremism, we need to make civil society
            institutions stronger and more efficient to restore the public trust
            in them.
          </p>
          <p>
            A native of Iran, Khatiri hopes to serve the U.S. after earning his
            SAIS degree. While he is still exploring careers, he aims to work on
            defense policy issues in either the government or private sector. He
            says he has always believed that a strong America—militarily,
            economically, politically, and institutionally—leads to a freer,
            more prosperous, and more peaceful world.
          </p>
          <Core.Share id="student-project" />
        </Core.ContentSection>
        <Core.MoreResources>
          For more about students’ experiences in the field, visit{' '}
          <a
            href="https://snapshot.sais-jhu.edu/"
            target="_blank"
            rel="noopener noreferrer"
          >
            snapshot.sais-jhu.edu
          </a>
        </Core.MoreResources>
      </Core.ContentWrapper>
    </Layout>
  );
};

export const query = graphql`
  query($directory: String!) {
    quoteImageKhashayar: file(
      relativePath: { regex: $directory }
      name: { eq: "khashayar-khatiri" }
    ) {
      ...QuoteImage
    }
    studentInternshipImage: file(
      relativePath: { regex: $directory }
      name: { eq: "real-world-internship" }
    ) {
      ...ContentWidthImage
    }
    studentPracticumImage: file(
      relativePath: { regex: $directory }
      name: { eq: "real-world-clean-water" }
    ) {
      ...MarginaliaImage
    }
    studentPracticumImage2: file(
      relativePath: { regex: $directory }
      name: { eq: "real-world-clean-water-2" }
    ) {
      ...ContentWidthImage
    }
  }
`;

export default DepartmentContent;
